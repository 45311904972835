import React, { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { useFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { convertToBgImage } from 'gbimage-bridge'
import { Link, useStaticQuery, graphql } from 'gatsby'
import * as Yup from 'yup'

import SEO from '../../components/atoms/Seo'
import Navbar from '../../components/organisms/Navbar'
import FormError from '../../components/atoms/FormError'
import FormItems from '../../components/atoms/FormItems'
import SubmitButton from '../../components/atoms/SubmitButton'
import ModalContainer from '../../components/atoms/ModalContainer'
import navigateSafely from '../../utils/navigateSafely'
import HomeSectionTitle from '../../components/atoms/HomeSectionTitle'
import HomeSectionDescription from '../../components/atoms/HomeSectionDescription'
import { API_SALES_URL, PHONE_REGEXP } from '../../config'

const formSchema = Yup.object().shape({
  phone: Yup.string().required('Votre numéro de téléphone est requis').matches(PHONE_REGEXP, 'Votre téléphone n\'est pas au bon format'),
  email: Yup.string().email('Votre email n\'est pas au bon format').required('Votre email est requis'),
  consent: Yup.bool().oneOf([true], 'Veuillez accepter d\'être contacté par TrouverMonLocalPro'),
  lastname: Yup.string().required('Votre nom est requis'),
  firstname: Yup.string().required('Votre prénom est requis'),
  password: Yup.string().required('Un mot de passe est requis'),
  confirmation:
    Yup.string()
      .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre')
      .required('Un mot de passe est requis')
})

const items = [{
  placeholder: 'Nom',
  type: 'text',
  name: 'lastname'
}, {
  placeholder: 'Prénom',
  type: 'text',
  name: 'firstname'
}, {
  placeholder: 'Numéro de téléphone',
  type: 'text',
  name: 'phone'
}, {
  placeholder: 'Email',
  type: 'email',
  name: 'email'
}, {
  placeholder: 'Mot de passe',
  type: 'password',
  name: 'password'
}, {
  placeholder: 'Confirmation mot de passe',
  type: 'password',
  name: 'confirmation'
}]

const Background = styled(BackgroundImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
`
const Title = styled(HomeSectionTitle)`
  margin-bottom: 16px;
`
const Submit = styled(SubmitButton)`
  margin-top: 12px;
`
const CheckBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
`
const CheckBox = styled.div`
  width: ${({ isOk }) => isOk ? '23' : '23'}px;
  height: ${({ isOk }) => isOk ? '20' : '20'}px;
  margin-top: 0.4rem;
  margin-right: 0.5rem;
  border-radius: 2px;
  padding: 2px;
  border: 1px solid ${({ theme }) => get(theme, 'blue', '#FFF')};
  background-color: ${({ isOk, theme }) => get(theme, isOk ? 'blue' : 'white', '#FFF')};
  cursor: pointer;
`
const CheckText = styled.label`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  line-height: 1.43;
  color: ${({ theme, isWhite }) => get(theme, isWhite ? 'white' : 'blue')};
`
const StyledLink = styled(Link)`
  color: ${({ theme }) => get(theme, 'blue')}
`
const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`
const NavLink = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
`

const Signin = ({ location }) => {
  const [error, setError] = useState('')
  const TITLE = 'Trouver Mon Local Pro - Création de compte'
  const DESCRIPTION = 'Créez un compte pour accéder aux fonctionnalités du site'

  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "home/tmlp-presentation.webp"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }`)

  const { values, handleSubmit, setFieldTouched, handleChange, setFieldValue, errors, touched, handleBlur } = useFormik({
    initialValues: {
      email: '',
      phone: '',
      lastname: '',
      password: '',
      firstname: '',
      confirmation: ''
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async values => {
      try {
        const { headers } = await axios.post(`${API_SALES_URL}/api/users`, {
          user:  {
            email: get(values, 'email'),
            password: get(values, 'password'),
            last_name: get(values, 'lastname'),
            first_name: get(values, 'firstname'),
            phone_number: get(values, 'phone'),
            password_confirmation: get(values, 'confirmation')
          }
        })

        localStorage.setItem('token', get(headers, 'authorization'))
        
        navigateSafely()
      } catch (e) {
        setError(`Impossible de créer un compte. ${get(e, 'response.data.message', '')}`)
      }
    }
  })

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}>
      </SEO >
      <Navbar location={location} />
      <Background
        {...convertToBgImage(getImage(placeholderImage))}>
        <ModalContainer
          top='62'
          size='500px'>
          <Title>Créer un compte</Title>
          <form onSubmit={handleSubmit}>
            <FormItems
              items={items}
              width={true}
              errors={errors}
              values={values}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue} />
            <CheckBoxContainer
              onClick={() => {
                setFieldValue('consent', !get(values, 'consent'))
                setFieldTouched('consent', true)
              }}>
              <CheckBox
                id='consent-checkbox'
                isOk={get(values, 'consent')}>
                {get(values, 'consent', false) && <FontAwesomeIcon icon='check' color='white' />}
              </CheckBox>
              <CheckText htmlFor='consent-checkbox'>
                J’ai lu et j’accepte les <StyledLink to='/conditions-generales-utilisation/'>conditions générales d’utilisation</StyledLink> et les <StyledLink to='/conditions-generales-vente/'>conditions générales de vente.</StyledLink>
              </CheckText>
            </CheckBoxContainer>
            {!get(values, 'consent') && get(errors, 'consent') && get(touched, 'consent') &&
              <FormError error={get(errors, 'consent')} />}
              {!isEmpty(error) && <FormError error={error} />}
            <Submit type='submit'>Créer un compte</Submit>
          </form>
          <Inline>
            <HomeSectionDescription color='blue'>Déjà inscrit·e ?</HomeSectionDescription>
            <NavLink
              color='brightOrange'
              to='/profile/login'>
              Se connecter
            </NavLink>
          </Inline>
        </ModalContainer>
      </Background>
    </>
  )
}

Signin.propTypes = {
  location: PropTypes.object.isRequired
}

export default Signin
